<script setup lang="ts">
import { computed } from "vue";
import { KippieCarouselButton } from "../";
import { Swiper } from "swiper/vue";
import { Keyboard, Pagination, Navigation, Autoplay, Mousewheel } from "swiper/modules";
import { SwiperModule } from "swiper/types";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

defineEmits(["loaded", "slideChange", "resize"]);
const props = withDefaults(
	defineProps<{
		space?: number;
		speed?: number;
		autoPlaySpeed?: number;
		autoPlay?: boolean;
		loop?: boolean;
		centered?: boolean;
		pagination?: boolean;
		paginationType?: "bullets" | "fraction";
		navigation?: boolean;
		slideOffset?: number;
		keyboard?: boolean;
		mousewheel?: boolean;
		freeMode?: boolean;
	}>(),
	{
		space: 32,
		speed: 600,
		autoPlaySpeed: 3000,
		autoPlay: false,
		loop: false,
		centered: false,
		pagination: false,
		mousewheel: false,
		paginationType: "bullets",
		navigation: false,
		keyboard: true,
		slideOffset: 0
	}
);

const modules = computed<SwiperModule[]>(() => {
	const arr = [Keyboard, Navigation, Pagination, Mousewheel];

	if (props.autoPlay) arr.push(Autoplay);

	return arr;
});
</script>

<template>
	<section>
		<Swiper
			observer
			observe-parents
			grab-cursor
			touch-release-on-edges
			:centered-slides="centered"
			:loop="loop"
			:space-between="space"
			:speed="speed"
			:slides-per-view="'auto'"
			:slides-offset-before="slideOffset"
			:slides-offset-after="slideOffset"
			:navigation="navigation"
			:mousewheel="mousewheel"
			:free-mode="freeMode"
			:keyboard="{
				enabled: keyboard
			}"
			:pagination="{
				enabled: pagination,
				type: paginationType,
				clickable: true
			}"
			:autoplay="{
				delay: autoPlaySpeed,
				stopOnLastSlide: !loop
			}"
			:modules="modules"
			class="w-full h-full overflow-hidden"
			:class="{
				'pagination-hide': !pagination,
				'pagination-bullets': paginationType === 'bullets',
				'pagination-fraction': paginationType === 'fraction',
				'!pb-8': pagination
			}"
			@swiper="$emit('loaded', $event)"
			@slide-change="$emit('slideChange', $event)"
			@resize="$emit('resize', $event)"
		>
			<template v-if="navigation">
				<KippieCarouselButton role="prev" />
				<KippieCarouselButton role="next" />
			</template>
			<slot />
		</Swiper>
	</section>
</template>

<style>
.swiper-slide{height:auto}.pagination-bullets .swiper-pagination-bullets{@apply flex justify-center gap-0 bottom-0}.pagination-bullets .swiper-pagination-bullet{@apply bg-gray opacity-50 scale-75 duration-200 ease-in-out}.pagination-bullets .swiper-pagination-bullet-active{@apply bg-black-light opacity-100 scale-100}.swiper-button-next,.swiper-button-prev{@apply !hidden}.pagination-hide .swiper-pagination{@apply hidden}.pagination-fraction .swiper-pagination{@apply bg-black-light text-white w-fit h-6 text-2xs font-medium py-1 px-2 rounded-full absolute top-5 right-5 left-auto z-[1]}.pagination-fraction .swiper-pagination-current{@apply relative left-[2px]}.pagination-fraction swiper-pagination-total{@apply relative right-[2px]}
</style>
